<template>
  <div class="wrapper">
    <base-button
      icon type="primary"
      class="flotingBtn"
      :class="[isActive ? 'tagBtn2' : 'tagBtn']"
      @click="isActive = !isActive"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-right"></i></span>
    </base-button>
    <base-button
      icon type="primary"
      class="flotingBtn2"
      @click="toggleClass"
      :class="[isActive ? 'tagBtn' : 'tagBtn2']"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-left"></i></span>
    </base-button>
    <Sidebar :class="{tagBtn: isActive}" />
    <div class="main-content" :class="{DashboardBody: isActive}">
        <SampleNavbar/>
        <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
            <b-breadcrumb>
                <b-breadcrumb-item href="#">Website</b-breadcrumb-item>
                <b-breadcrumb-item href="#" active>Image Upload</b-breadcrumb-item>
            </b-breadcrumb>
        </base-header>
        <b-container fluid class="mt--7">
            <b-row>
                <b-col md="4">
                    <b-card type="default" header-classes="bg-transparent">
                        <b-form style="text-align: left">
                            <b-row>
                                <b-col md="12">
                                    <div id="my-strictly-unique-vue-upload-multiple-image" style="display: flex; justify-content: center;">
                                        <vue-upload-multiple-image
                                        @upload-success="uploadImageSuccess"
                                        @before-remove="beforeRemove"
                                        @edit-image="editImage"
                                        :data-images="images"
                                        idUpload="myIdUpload"
                                        editUpload="myIdEdit"
                                        ></vue-upload-multiple-image>
                                    </div>
                                </b-col>
                                <b-col md="12" class="mt-4">
                                    <base-button block type="primary" @click="addImage">Add Image</base-button>
                                </b-col>
                            </b-row>
                        </b-form>
                    </b-card>
                </b-col>
                <b-col md="8">
                    <b-card type="default" header-classes="bg-transparent">
                        <!-- <b-row  class="mb-2">
                            <b-col md="8"></b-col>
                            <b-col md="4"><base-button block type="primary">Export to CSV</base-button></b-col>
                        </b-row> -->
                        <vue-good-table
                        :columns="columns"
                        :rows="rows"
                        :line-numbers="true"
                        :search-options="{
                        enabled: true,
                        placeholder: 'Search this table',
                        }"
                        :pagination-options="{
                        enabled: true,
                        mode: 'records'
                        }"
                        >
                        <template slot="table-row" slot-scope="props" style="w">
                          <span v-if="props.column.field == 'before'">
                            <img :src="props.row.images_url" width="100%" />
                          </span>
                            <span v-if="props.column.field == 'after'">
                                <base-button
                                icon type="danger"
                                size="sm"
                                
                                >
                                <!-- @click="checkedClick(props.row.images_id)" -->
                                <span class="btn-inner--icon">
                                  <i class="ni ni-check-bold" v-if="props.row.images_active"></i>
                                  <i class="ni ni-fat-remove" v-else></i>
                                </span>
                                </base-button>
                            </span>
                            <span v-else>
                                {{props.formattedRow[props.column.field]}}
                            </span>
                        </template>
                        </vue-good-table>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
        <Footer/>
    </div>
  </div>
</template>
<script>
import Sidebar from './../../Starter/Sidebar'
import SampleNavbar from './../../Starter/SampleNavbar'
import Footer from './../../Starter/SampleFooter'
import VueUploadMultipleImage from 'vue-upload-multiple-image'
import { API_URL } from '../../../router/api'
export default {
  name: 'webiste-image-upload',
  components: {
    Sidebar,
    SampleNavbar,
    Footer,
    VueUploadMultipleImage
  },
  data () {
    return {
      isActive: false,
      checked: true,
      columns: [
        {
          label: 'Image',
          field: 'before',
          width: '100px',
        },
        {
          label: 'Image Url',
          field: 'images_url'
        },
        {
          label: 'Actions',
          field: 'after'
        }
      ],
      images: [],
      imageData:[],
      rows: []
    }
  },
  // computed: {
  //   rows () {
  //     return []
  //   }
  // },
  methods: {
    toggleClass: function (event) {
      this.isActive = !this.isActive
    },
    uploadImageSuccess(formData, index, fileList) {
      this.imageData = fileList;
    },
    beforeRemove (index, done, fileList) {
      var r = confirm("remove image")
      if (r == true) {
        done()
      } else {
      }
    },
    editImage (formData, index, fileList) {
      console.log('edit data', formData, index, fileList)
    },
    addImage() {
      const self = this
      if(self.imageData.length > 0) {
        self.axios.post(`${API_URL}admin_add_image`,{image:  this.imageData})
        .then(response => {
          let obj = response.data
          if(obj.status === true) {
            self.$store.dispatch('alertSuccess', obj.message)
            self.images = []
            self.imageShow()
          }else {
            self.$store.dispatch('alertError', obj.message)
          }
        })
      }else {
        this.$store.dispatch('alertError', "Add Image")
      }
    },
    imageShow() {
      const self = this
      self.axios.post(`${API_URL}admin_show_image`)
      .then(response => {
        let obj = response.data
        self.rows = obj
      })
    },
    checkedClick(id) {
      this.rows.map(row => {
        if(row.images_id === id) {
          row.images_active = !row.images_active; 
          let active = row.images_active;
          this.axios.post(API_URL+'admin_employee_type_active',{id: id, active: active})
          .then((res) => {
            let status = res.data.status;
            let message = res.data.message;
            if(status == true) {
                this.$store.dispatch('getEmployeeTypeList');
                this.$swal.fire({
                  icon: 'success',
                  text: `${message}`,
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
                })
            }else {
              this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${message}`,
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
              })
            }
          })
        }
        return row
      })
    }
  },
  mounted() {
    this.imageShow();
  }
}
</script>
<style lang="scss">
.DashboardBody {
  margin-left: 0px !important;
}
.tagBtn {
  display: none !important;
}
.tagBtn2 {
  display: block !important;
}
#my-strictly-unique-vue-upload-multiple-image {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
